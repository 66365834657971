import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import { Formik } from "formik";
import Select from "react-select";
import axios from "axios";
import * as Yup from "yup";
import CloseIcon from "../images/svg/close-green.svg";
import { LanguageContext } from "../context";

const FullScreenNewsletter = ({ onClick }) => {
  const [error, setError] = useState(null);
  const { getLanguage } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [mailSent, setMailSent] = useState(null);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);

  const selectOptions = [
    { value: "frau", label: t("ms") },
    { value: "herr", label: t("mr") },
    { value: "divers", label: t("divers") },
  ];

  const initialValues = {
    title: "",
    givenName: "",
    familyName: "",
    email: "",
    language: getLanguage(),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string(),
    givenName: Yup.string()
      .min(2, `${t("tooShort")}!`)
      .max(50, `${t("tooLong")}!`),
    familyName: Yup.string()
      .min(2, `${t("tooShort")}!`)
      .max(50, `${t("tooLong")}!`),
    email: Yup.string()
      .email(t("validEmail"))
      .required(`${t("email")} ${t("required")}`),
  });

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { siteUrl } = data.site.siteMetadata;

  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#69FF75" : "#fff",
      ":active": {
        backgroundColor: state.isSelected ? "#E1E1FF" : "#E1E1FF",
      },
      ":hover": {
        backgroundColor: state.isSelected ? "#69FF75" : "#E1E1FF",
      },
    }),
    control: (base) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
  };

  return (
    <div className="h-full w-full fixed z-50 left-0 top-0 bg-safiaBlue overflow-x-hidden">
      <div className="container mx-auto py-20 px-8 relative">
        <div className="flex flex-row items-center justify-between mb-10">
          <h1 className="text-h1mobile md:text-h1desktop w-9/12">
            {t("receiveLatestNews")}
          </h1>
          <div onClick={onClick} className="cursor-pointer">
            <CloseIcon />
          </div>
        </div>
        <h2 className="text-h2mobile md:text-h2desktop w-full lg:w-10/12 font-safiaRegular">
          {t("receiveLatestNewsDesc")}
        </h2>
        <div className="mt-20 w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsLoading(true);
              axios({
                method: "post",
                url: `${siteUrl}/api/newsletter/index.php`,
                headers: { "content-type": "application/json" },
                data: values,
              })
                .then((result) => {
                  setIsLoading(false);

                  if (result.status === 200) {
                    setMailSent(true);
                    setTimeout(() => {
                      setMailSent(false);
                    }, 5000);
                    resetForm(initialValues);
                  }
                })
                .catch((error) => {
                  setError(error.message);
                  setTimeout(() => {
                    setError(null);
                  }, 5000);
                  setIsLoading(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} action="#">
                <div className="w-6/12 lg:w-3/12">
                  <label className="text-body text-white">
                    {t("title")}
                    <Select
                      styles={customStyles}
                      value={selectedOption}
                      onChange={setSelectedOption}
                      options={selectOptions}
                      placeholder={t("pleaseChoose")}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isSearchable={false}
                    />
                    <span className="text-error text-xs">
                      {errors.title && touched.title && errors.title}
                    </span>
                  </label>
                </div>
                <div className="w-full lg:w-5/12 mt-5">
                  <label className="text-body text-white">
                    {`${t("firstName")}`}
                    <input
                      className="block safiainput w-full"
                      type="text"
                      name="givenName"
                      value={values.givenName}
                      onChange={handleChange}
                    />
                    <span className="text-error text-xs">
                      {errors.givenName &&
                        touched.givenName &&
                        errors.givenName}
                    </span>
                  </label>
                </div>
                <div className="w-full lg:w-5/12 mt-5">
                  <label className="text-body text-white">
                    {`${t("familyName")}`}
                    <input
                      className="block safiainput w-full"
                      type="text"
                      name="familyName"
                      value={values.familyName}
                      onChange={handleChange}
                    />
                    <span className="text-error text-xs">
                      {errors.familyName &&
                        touched.familyName &&
                        errors.familyName}
                    </span>
                  </label>
                </div>
                <div className="w-full lg:w-5/12 mt-5">
                  <label className="text-body text-white">
                    {`${t("email")} *`}
                    <input
                      className="block safiainput w-full"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <span className="text-error text-xs">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </label>
                </div>
                <p className="text-body text-white mt-8 mb-12">
                  {t("fieldsAreRequiredNewsletter")}
                </p>
                <button
                  className="flex cursor-pointer rounded-full h-10 pl-5 pr-5 items-center justify-items-center text-body font-safiaBold buttonRound"
                  type="submit"
                >
                  {isLoading
                    ? t("isLoadingNewsletter")
                    : t("subscribeNewsletter")}
                </button>
                <div
                  className={`bg-screamingGreen text-center px-8 py-4 text-safiaBlue mt-10 contactformmessage ${
                    mailSent ? "isVisible" : "hidden"
                  }`}
                >
                  <span>{t("newslettersuccess")}</span>
                </div>
                <div
                  className={`bg-error text-center px-8 py-4 text-safiaBlue mt-10 contactformmessage ${
                    error ? "isVisible" : "hidden"
                  }`}
                >
                  <span>{t("newslettererror")}</span>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FullScreenNewsletter;
