import React from "react";
import "./buttonround.css";

const ButtonRound = ({ name, onPress, alternate }) => {
  return (
    <div
      onClick={onPress}
      className={`pl-5 pr-5 pt-2 pb-2 ${
        alternate ? "buttonroundAlternate" : "buttonRound"
      } text-body font-safiaBold  rounded-full cursor-pointer`}
    >
      {name}
    </div>
    // <a
    //   className="buttonround no-underline"
    //   href="#"
    //   // style={{
    //   //   paddingLeft: "21px",
    //   //   paddingRight: "21px",
    //   //   paddingTop: "8px",
    //   //   paddingBottom: "8px",
    //   //   backgroundColor: "#140E55",
    //   //   borderRadius: "80px",
    //   //   minWidth: "210px",
    //   //   maxWidth: "214px",
    //   //   color: "#fff",
    //   //   fontSize: "16px",
    //   //   fontFamily: "Bold",
    //   //   textAlign: "center",
    //   // }}
    // >
    //   {name}
    // </a>
  );
};

export default ButtonRound;
