import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ButtonRound from "./ButtonRound";
import FullScreenNewsletter from "./FullScreenNewsletter";

const NewsletterSubscribe = ({ backgroundColor }) => {
  const { t } = useTranslation();
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);

  if (isNewsletterOpen) {
    return <FullScreenNewsletter onClick={() => setIsNewsletterOpen(false)} />;
  }

  return (
    <div style={{ backgroundColor: "#69FF75" }}>
      <div className="container mx-auto py-10 md:flex md:items-center md:justify-between md:py-12 px-8">
        <div>
          <h2
            className="text-h2mobile sm:text-h2desktop"
            style={{ fontFamily: "Bold", color: "#140E55" }}
          >
            {t("newsletterHeadline")}
          </h2>
          <p
            className="mb-10 mt-6 w-full md:w-3/6"
            style={{
              color: "#140E55",
              fontSize: "16px",
            }}
          >
            {t("newsletterSubheadline")}
          </p>
        </div>
        <div
          onClick={() => setIsNewsletterOpen(true)}
          className="flex justify-center md:flex-none md:pr-8"
        >
          <ButtonRound name={t("newsletterSubscribe")} alternate={true} />
        </div>
      </div>
    </div>
  );
};

NewsletterSubscribe.propTypes = {
  backgroundColor: PropTypes.string,
};

NewsletterSubscribe.defaultProps = {
  backgroundColor: "#69FF75",
};

export default NewsletterSubscribe;
